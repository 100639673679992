<template>
  <fw-modal :active.sync="active" size="min" width="36rem" trap-focus @close="$emit('close')">
    <fw-panel v-if="rating" :title="$t('feedback')">
      <div>
        <b-rate :show-score="true" :value="rating.score" size="is-large" :spaced="true" :disabled="true" />
      </div>
      <form class="mt-2" @submit.prevent="saveFeedback()">
        <fw-label>{{ $t('message.label') }}</fw-label>

        <b-input ref="inputMessage" v-model="tmpMessage" type="textarea" :placeholder="$t('message.placeholder')" />

        <fw-tip v-if="$v.tmpMessage.$error" error>
          {{ $t('message.maxLength') }}
        </fw-tip>

        <div class="flex justify-end mt-4 gap-5 items-center">
          <fw-button type="link-muted" @click.native="$emit('close')">
            {{ $t('close') }}
          </fw-button>
          <fw-button v-if="canSubmit" type="primary" role="submit" wider>
            {{ $t('save') }}
          </fw-button>
        </div>
      </form>
    </fw-panel>
  </fw-modal>
</template>

<script>
import { maxLength } from 'vuelidate/lib/validators'
import ServiceTasks from '@/fw-modules/fw-core-vue/tasks/services/ServiceTasks'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  validations: {
    tmpMessage: {
      message: { max: maxLength(5000) },
    },
  },

  props: {
    active: {
      type: Boolean,
    },
    taskId: {
      type: String,
      required: true,
    },
    rating: {
      type: Object,
    },
  },

  data() {
    return {
      tmpMessage: '',
    }
  },

  computed: {
    canSubmit() {
      return this.tmpMessage && this.tmpMessage.length > 0
    },
  },

  watch: {
    active(newVal) {
      if (newVal) this.focusInput()
    },
  },

  methods: {
    focusInput() {
      this.$nextTick(() => {
        this.$refs.inputMessage.focus()
      })
    },

    async saveFeedback() {
      console.log('saveFeedback', this.tmpMessage, this.rating)
      this.$v.$touch()
      if (this.$v.$invalid) return

      await utils.tryAndCatch(
        this,
        async () => {
          await ServiceTasks.updateUserSupportTaskRating(this.taskId, this.rating.key, {
            message: this.tmpMessage,
          })
          this.$emit('close')
        },
        null,
        () => {
          this.savingMessageError = true
        }
      )
    },
  },
}
</script>

<i18n>
{
  "pt": {
		"feedback": "Obrigado pela sua classificação!",
    "message": {
      "label": "Se desejar, deixe-nos ainda uma mensagem com sugestões de como podemos melhorar o nosso apoio.",
      "placeholder": "Escreva aqui a sua mensagem",
			"maxLength": "Insira no máximo 65.000 caracteres"
    },
    "createdSuccessfully": "Feedback submetido com sucesso!",
		"save": "Enviar",
    "close": "Fechar janela"
  },
  "en": {
		"feedback": "Obrigado pela sua classificação!",
    "message": {
      "label": "Se desejar, deixe-nos ainda uma mensagem com sugestões de como podemos melhorar o nosso apoio.",
      "placeholder": "Escreva aqui a sua mensagem",
			"maxLength": "Insira no máximo 65.000 caracteres"
    },
    "createdSuccessfully": "Feedback submetido com sucesso!",
		"save": "Enviar",
    "close": "Fechar janela"
  }
}
</i18n>
