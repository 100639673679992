<template>
  <div>
    <fw-panel
      v-if="tmpMessage"
      :title-size="type == 'normal' ? 'h3' : 'h4'"
      :marginless="type == 'minimal'"
      :title="tmpMessage.key ? $t('editMessage') : title ? title : $t('newMessage')"
    >
      <template v-if="showPublicPrivateToggle && tmpMessage.is_private" #toolbar>
        <fw-tag type="light-orange" :size="type == 'normal' ? 'md' : 'sm'" class="flex items-center gap-1">
          <fw-icon-lock-solid class="w-4 h-4" /> Interna
        </fw-tag>
      </template>
      <template #default>
        <div v-if="type == 'normal' && showPublicPrivateToggle && !tmpMessage.key" class="my-3">
          <b-switch
            v-model="tmpMessage.is_private"
            type="is-warning"
            :true-value="true"
            :false-value="false"
            :disabled="tmpMessage.key || forcePrivateMessage"
          >
            {{ $t('privateMessages') }}<br />
            <div v-if="!tmpMessage.key" class="text-xs text-gray-500" v-html="$t('selectThisOption')"></div>
          </b-switch>
        </div>
        <div v-if="$slots['description'] || description">
          <slot name="description">
            <div class="text-sm text-gray-500">{{ description }}</div>
          </slot>
        </div>
        <div :class="{ 'mt-3': type == 'normal' }">
          <fw-label>{{ $t('description.label') }}</fw-label>
          <HtmlEditor
            ref="editor"
            v-model="tmpMessage.description"
            class="mb-1.5 w-full content"
            :editor-class="type == 'normal' ? 'min-h-40' : ''"
            :placeholder-text="$t('description.placeholder')"
            :autofocus="true"
            :save-content-user-settings="saveContentUserSettings"
          />

          <fw-tip v-if="$v.tmpMessage.description.$error" error>
            <span v-if="!$v.tmpMessage.description.required">{{ $t('errors.requiredMessage') }}</span>
            <span v-else>{{ $t('errors.invalidMessage') }}</span>
          </fw-tip>
        </div>
        <div
          v-if="allowFiles && (type == 'normal' || (tmpMessage.files && tmpMessage.files.length > 0))"
          :class="{ 'mt-5': type == 'normal' }"
        >
          <fw-label>{{ $t('files') }}</fw-label>
          <div class="mb-2">
            <div v-if="tmpMessage.files && tmpMessage.files.length > 0" class="files mb-1.5">
              <RecordFileEntry
                v-for="(file, f) in tmpMessage.files"
                :key="file.key"
                :allow-classified="false"
                :file="file"
                can-edit
                can-remove
                @save="saveFile(f, $event)"
                @remove="removeFile(f)"
                @download="downloadFile(file)"
              />
            </div>
            <Uploader
              v-if="type == 'normal'"
              :label="$t('uploadFiles')"
              :is-docked="true"
              :layout="'minimal'"
              reference-id="uploader_update"
              allowed="all"
              :clear-after="true"
              input-id="upload_input"
              :files.sync="updateFilesToUpload"
              :size="0"
              :new-file-context="{}"
              file-type="file"
              file-code=""
              class="cursor-pointer update-modal-uploader"
              uploader-class="w-full rounded"
              @upload="uploaded"
            />
          </div>
        </div>
      </template>
    </fw-panel>
    <fw-tip v-if="instructions">{{ instructions }}</fw-tip>
    <div class="flex items-center justify-end gap-5" :class="{ 'pt-5': !instructions && type == 'normal' }">
      <div class="flex-1">
        <div v-if="type == 'minimal' && showPublicPrivateToggle && !tmpMessage.key">
          <b-switch
            v-model="tmpMessage.is_private"
            type="is-warning"
            :true-value="true"
            :false-value="false"
            :disabled="tmpMessage.key || forcePrivateMessage"
            size="is-small"
          >
            {{ $t('privateMessages') }}<br />
            <div v-if="!tmpMessage.key" class="text-xs text-gray-500" v-html="$t('selectThisOption')"></div>
          </b-switch>
        </div>
      </div>
      <Uploader
        v-if="type == 'minimal'"
        :label="$t('uploadFiles')"
        :is-docked="true"
        :layout="'tiny'"
        reference-id="uploader_update"
        allowed="all"
        :clear-after="true"
        input-id="upload_input"
        :files.sync="updateFilesToUpload"
        :size="0"
        :new-file-context="{}"
        file-type="file"
        file-code=""
        class="cursor-pointer update-modal-uploader"
        uploader-class="w-full rounded"
        @upload="uploaded"
      />
      <fw-button v-if="showCancelOption" type="link-muted" :disabled="savingMessage" @click.native="close">
        {{ $t('cancel') }}
      </fw-button>
      <fw-button
        type="primary"
        class="px-5"
        :disabled="savingMessage"
        :loading="savingMessage"
        @click.native="saveMessage"
      >
        {{ !tmpMessage.key ? $t('send') : $t('save') }}
      </fw-button>
    </div>
  </div>
</template>

<script>
import HtmlEditor from '@/fw-modules/fw-core-vue/ui/components/form/HtmlEditor'
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/PathUploader.vue'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

import { required, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  components: {
    HtmlEditor,
    Uploader,
    RecordFileEntry,
  },

  validations() {
    return {
      tmpMessage: {
        description: this.requiredDescription
          ? { required, min: minLength(2), max: maxLength(65000) }
          : { max: maxLength(65000) },
      },
    }
  },

  props: {
    message: {
      type: Object,
      default: () => {
        return { description: '', is_private: true, files: [] }
      },
    },
    savingMessage: {
      type: Boolean,
      default: false,
    },
    showPublicPrivateToggle: {
      type: Boolean,
      default: false,
    },
    forcePrivateMessage: {
      type: Boolean,
      default: false,
    },
    allowFiles: {
      type: Boolean,
      default: true,
    },
    ignoreConfirmPublicMessage: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    instructions: {
      type: String,
      default: null,
    },
    requiredDescription: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'normal',
    },
    showCancelOption: {
      type: Boolean,
      default: true,
    },
    saveContentUserSettings: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      updateFilesToUpload: [],
      tmpMessage: {},
    }
  },

  created() {
    this.tmpMessage = JSON.parse(JSON.stringify(this.message))
    if (!('files' in this.tmpMessage) || !this.tmpMessage.files) {
      this.tmpMessage['files'] = []
    }
  },

  methods: {
    close() {
      this.$v.$reset()
      this.$emit('close')
    },

    saveMessage() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      if (!this.tmpMessage.is_private && !this.ignoreConfirmPublicMessage) {
        this.$buefy.dialog.confirm({
          type: 'is-primary',
          title: this.showPublicPrivateToggle ? this.$t('publicMessage') : this.$t('sendMessage'),
          message: this.showPublicPrivateToggle ? this.$t('messageIsPublicConfirm') : this.$t('sendMessageConfirm'),
          onConfirm: () => {
            this.$emit('save', this.tmpMessage)
            this.$refs.editor.deleteDraft()
          },
          confirmText: this.$t('send'),
          cancelText: this.$t('cancel'),
        })
      } else {
        this.$emit('save', this.tmpMessage)
        this.$refs.editor.deleteDraft()
      }
    },

    downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },

    removeFile(f) {
      console.log('remove file', f)
      this.$buefy.dialog.confirm({
        message: this.$t('deleteFileConfirm'),
        confirmText: this.$t('delete'),
        cancelText: this.$t('cancel'),
        onConfirm: async () => {
          this.tmpMessage.files.splice(f, 1)
        },
      })
    },

    saveFile(f, updatedFile) {
      console.log('save filename', f, updatedFile)
      this.$set(this.tmpMessage.files, f, { filename: updatedFile.title, key: updatedFile.key })
    },

    uploaded(files) {
      for (const file of files) {
        if (file.response.data) {
          if (file.response.status === 'success') {
            this.tmpMessage.files.push({
              key: file.response.data.file.key,
              filename: file.response.data.file.filename,
            })
          }
        }
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "close": "Fechar",
    "save": "Guardar",
    "cancel": "Cancelar",
    "delete": "Eliminar",
    "send": "Enviar",
    "description": {
      "label": "Mensagem",
      "placeholder": "Escreva aqui a sua mensagem..."
    },
    "editMessage": "Editar mensagem",
    "files": "Ficheiros",
    "newMessage": "Nova mensagem",
    "publicMessage": "Mensagem não interna",
    "privateMessages": "Mensagem interna",
    "messageIsPublicConfirm": "A mensagem que está prestes a enviar não é interna. Tem a certeza que deseja continuar?",
    "sendMessageConfirm": "Tem a certeza que deseja enviar esta mensagem?",
    "selectThisOption": "Selecione esta opção para que esta mensagem seja apenas vísiveis a gestores e operadores.",
    "sendMessage": "Enviar mensagem",
    "noMessages": "Sem mensagems",
    "addMessage": "Adicionar nova mensagem",
    "deleteFileConfirm": "Tem a certeza que deseja eliminar o ficheiro?",
    "uploadFiles": "Carregar ficheiros",
    "deleteMessageConfirm": "Tem a certeza que deseja eliminar a mensagem?",
    "errors": {
      "requiredMessage": "Insira uma mensagem",
      "invalidMessage": "mensagem inválida"
    }
  },
  "en": {
    "save": "Save",
    "close": "Close",
    "cancel": "Cancel",
    "delete": "Delete",
    "send": "Send",
    "description": {
      "label": "Message",
      "placeholder": "Write your message"
    },
    "files": "Ficheiros",
    "editMessage": "Edit message",
    "newMessage": "New message",
    "publicMessage": "Non-internal message",
    "privateMessages": "Private message",
    "messageIsPublicConfirm": "You are about to send a non-internal message. Are you sure?",
    "sendMessageConfirm": "Are you sure you want to send this message?",
    "selectThisOption": "Select this option for messages to be visible only by those responsible.",
    "noMessages": "No messages",
    "addMessage": "Add new message",
    "sendMessage": "Send message",
    "deleteFileConfirm": "Are you sure you want to delete the file?",
    "uploadFiles": "Upload files",
    "errors": {
      "requiredMessage": "Enter a message",
      "invalidMessage": "Invalid message"
    }
  }
}
</i18n>
